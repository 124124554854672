import React from "react"
import Navbar from "../../components/navbar"
import Container from "../../components/container"
import Header from "../../components/header"
import Layout from "../../components/layout"
import BackgroundShape from "../../components/backgroundShape"
import Head from "../../components/head"
import TableEntryChannel from "../../components/tableEntryChannel"
import TableContacts from "../../components/tableContacts"
import { graphql, useStaticQuery } from "gatsby"

export default function ChannelStatus () {
    const data = useStaticQuery(graphql`
        query {
            allContentfulChannelIntegrations{
                edges {
                    node {
                        title
                        fetchOrders
                        createFulfillments
                        productCreation
                        checkoutRates
                        cancellationSync
                        updateInventory
                        updatePricing
                        inventorySync
                        cloneOrder
                    }
                }    
            }
        }
    `)


    return (
        <Layout navbar="transparent">

        <Head title="Channel Integrations" description="From basic dropshipping & automation to Advanced and custom reporting, we’ve got you covered. Find the right package for your business"/>
         
        <BackgroundShape style={{shape: "clip-fixed-small", color: "gradient", particles: true}}/>
        <Container style={{classes: "container" }}>
          <div>
            <Header content={{title: "Channel Integrations", text: "Check the current capabilities of our integrations"}} style={{color: "text-center"}}/>
          </div>
        </Container>
        <Container style={{classes: "container mt-5 pt-5" }}>
            <div className="testof" >
                <div className='channel-table-entry' data-sal='slide-up' data-sal-easing='ease' data-sal-delay='100' data-sal-duration='800'>
                    <p className="table-header"></p>
                    <p className="table-header">Fetch<br/>Orders</p>
                    <p className="table-header">Create<br/>Fulfillments</p>
                    <p className="table-header">Product<br/>Creation</p>
                    <p className="table-header">Checkout<br/>Rates</p>
                    <p className="table-header">Cancellation<br/>Sync</p>
                    <p className="table-header">Update<br/>Inventory</p>
                    <p className="table-header">Update<br/>Pricing</p>
                    <p className="table-header">Inventory<br/>Sync</p>
                    <p className="table-header">Clone<br/>Order</p>
                </div>
                {data.allContentfulChannelIntegrations.edges.map((edge) => {
                    return(
                        <TableEntryChannel
                        data={{
                            title: edge.node.title,
                            fetchOrders: edge.node.fetchOrders,
                            createFulfillments: edge.node.createFulfillments,
                            productCreation: edge.node.productCreation,
                            checkoutRates: edge.node.checkoutRates,
                            cancellationSync: edge.node.cancellationSync,
                            updateInventory: edge.node.updateInventory,
                            updatePricing: edge.node.updatePricing,
                            inventorySync: edge.node.inventorySync,
                            cloneOrder: edge.node.cloneOrder,
                        }}/>
                        )})
                    }
            </div>
        <TableContacts title='Is your store on a platform not listed above?'/>
        </Container>
      </Layout>
    )
}
