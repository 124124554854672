import React from 'react'

const TableEntryChannel = ({ data }) => {
  const generateEntry = (data) => {
    if (data === 'Yes') {
      return <p className='entry entry-success'>Yes</p> }
    else if (data === 'No') {
      return <p className='entry entry-warning'>No</p> }
    else if (data === 'Automatic') {
      return <p className='entry entry-info'>Automatic</p> }
    else if (data === 'Beta') {
      return <p className='entry entry-beta'>{data}</p> }
    else if (data === 'Coming Soon') {
      return <p className='entry entry-neutral'>{data}</p> }
  }

  return (
    <div className='channel-table-entry'>
        <div><img className='entry-logo' src={require(`../assets/icons/platform/round/${data.title}.svg`)}/></div>
        <div>{generateEntry(data.fetchOrders)}</div>
        <div>{generateEntry(data.createFulfillments)}</div>
        <div>{generateEntry(data.productCreation)}</div>
        <div>{generateEntry(data.checkoutRates)}</div>
        <div>{generateEntry(data.cancellationSync)}</div>
        <div>{generateEntry(data.updateInventory)}</div>
        <div>{generateEntry(data.updatePricing)}</div>
        <div>{generateEntry(data.inventorySync)}</div>
        <div>{generateEntry(data.cloneOrder)}</div>
    </div>
  )
}

export default TableEntryChannel